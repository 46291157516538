<template>
  <ModuleForm
    :module-name="$t('moduleForm.topic')"
    :module="topic"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        @click="save"
      />
      <ButtonDeleteWithUsages
        v-if="mode === 'update'"
        @deleteRecord="deleteRecord"
        @load-data="checkUsages"
        @page-change="setUsagesPageAndGetRecords"
        :data="usages.data"
        :total-count="usages.totalCount"
        :page="usages.page"
        :showLoader="usages.showLoader"
        :config="usagesDataTableConfig"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/topic')"
      />
    </template>
    <template #left-column>
      <Input
        v-model="topic.title"
        @blur="$v.topic.title.$touch()"
        :error="$v.topic.title.$error"
        id="topic_title"
        :label="$t('topic.title')"
        :placeholder="$t('topic.title_placeholder')"
        :required="true"
        :disabled="disabled"
      />
      <div class="two-columns">
        <Select
          v-model="topic.category"
          :options="topicCategoryValues"
          id="topic_category"
          :label="$t('topic.category')"
          :noEmptyValue="true"
          :required="true"
          :disabled="disabled"
          class="two-columns__column"
        />
        <Select
          v-model="topic.site"
          :options="sites"
          id="topic_site"
          :label="$t('topic.site')"
          :required="true"
          @blur="$v.topic.site.$touch()"
          :error="$v.topic.site.$error"
          :disabled="disabled"
          class="two-columns__column"
        />
      </div>
      <div class="form-group">
        <Input
          v-model="topic.slug"
          @blur="$v.topic.slug.$touch()"
          :error="$v.topic.slug.$error"
          id="topic_slug"
          :tooltip="$t('topic.slug_desc')"
          :label="$t('topic.slug')"
          :required="true"
          :disableFormGroup="true"
          :disabled="disabled"
        />
        <a v-if="mode === 'update'" :href="topicUrl" target="_blank">
          {{ topicUrl }}
        </a>
      </div>
      <!-- see https://newsandmedia.atlassian.net/browse/CMS-4305 -->
      <div v-if="vlm" class="form-group">
        <div class="title-text">
          {{ $t('topic.description') }}
        </div>
        <RichTextEditor
          v-model="topic.description"
          :other-options="richTextEditorConfig"
          id="topic_description"
          rows="6"
          :readonly="disabled"
        />
      </div>
      <div class="title-text">
        {{ $t('topic.image') }}
      </div>
      <ModuleFormPhotoBox
        :image="topicImage"
        :disabled="disabled"
        @set-media="setImage"
        @remove-media="removeImage"
      />
      <div class="title-text">
        {{ $t('topic.image_bg') }}
      </div>
      <ModuleFormPhotoBox
        :image="topicImageBg"
        :disabled="disabled"
        @set-media="setImageBg"
        @remove-media="removeImageBg"
      />
    </template>
    <template #right-column>
      <div class="header-text">
        {{ $t('topic.query') }}
      </div>
      <div class="two-columns">
        <div class="form-group two-columns__column">
          <div class="title-text">
            {{ $t('topic.tags_required') }}
            <Tooltip :title="$t('topic.tags_required_desc')" />
          </div>
          <MultiSelect
            v-model="topic.expanded.tagsRequired"
            :options="tagsRequired"
            :custom-label="tag => `${tag.title} (${tag.articleCount})`"
            track-by="id"
            :preselect-first="false"
            :loading="isLoading"
            :internal-search="false"
            :options-limit="300"
            :limit="100"
            :max-height="600"
            :show-no-results="false"
            @search-change="findTagsRequired"
            id="topic_tagsRequired"
            :disabled="disabled"
          />
        </div>
        <div class="form-group two-columns__column">
          <div class="title-text">
            {{ $t('topic.tags_optional') }}
            <Tooltip :title="$t('topic.tags_optional_desc')" />
          </div>
          <MultiSelect
            v-model="topic.expanded.tagsOptional"
            :options="tagsOptional"
            :custom-label="tag => `${tag.title} (${tag.articleCount})`"
            track-by="id"
            :preselect-first="false"
            :loading="isLoading"
            :internal-search="false"
            :options-limit="300"
            :limit="100"
            :max-height="600"
            :show-no-results="false"
            @search-change="findTagsOptional"
            id="topic_tagsOptional"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="two-columns">
        <div class="form-group two-columns__column">
          <div class="title-text">
            {{ $t('topic.sites_selected') }}
            <Tooltip :title="$t('topic.sites_selected_desc')" />
          </div>
          <MultiSelect
            v-model="topic.expanded.sitesSelected"
            :options="sites"
            label="title"
            track-by="id"
            id="topic_sitesSelected"
            :disabled="disabled"
          />
        </div>
        <div class="form-group two-columns__column">
          <div class="title-text">
            {{ $t('topic.article_types') }}
            <Tooltip :title="$t('topic.article_types_desc')" />
          </div>
          <MultiSelect
            v-model="topic.expanded.articleTypes"
            :options="articleTypeValues"
            label="title"
            track-by="id"
            id="topic_articleTypes"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="header-text">
        {{ $t('topic.meta') }}
      </div>
      <Input
        v-model="topic.meta.title"
        @blur="$v.topic.meta.title.$touch()"
        :error="$v.topic.meta.title.$error"
        id="topic_meta_title"
        :label="$t('topic.meta_title')"
        :disabled="disabled"
      />
      <Input
        v-model="topic.meta.description"
        @blur="$v.topic.meta.description.$touch()"
        :error="$v.topic.meta.description.$error"
        id="topic_meta_description"
        :label="$t('topic.meta_description')"
        :disabled="disabled"
      />
      <Input
        v-model="topic.meta.keywords"
        @blur="$v.topic.meta.keywords.$touch()"
        :error="$v.topic.meta.keywords.$error"
        id="topic_meta_keywords"
        :label="$t('topic.meta_keywords')"
        :disabled="disabled"
      />
      <div class="header-text">
        {{ $t('topic.sport_table.title') }}
      </div>
      <div class="two-columns">
        <Input
          v-model="topic.sportTable.seasonId"
          @blur="$v.topic.sportTable.seasonId.$touch()"
          :error="$v.topic.sportTable.seasonId.$error"
          id="topic_sportTable_seasonId"
          type="number"
          :min="0"
          :label="$t('topic.sport_table.season_id')"
          :disabled="disabled"
          class="two-columns__column"
        />
        <Input
          v-model="topic.sportTable.activeTab"
          @blur="$v.topic.sportTable.activeTab.$touch()"
          :error="$v.topic.sportTable.activeTab.$error"
          id="topic_sportTable_activeTab"
          type="number"
          :min="1"
          :label="$t('topic.sport_table.active_tab')"
          :disabled="disabled"
          class="two-columns__column"
        />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import { helpers, integer, maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import MultiSelect from '@/components/form/select/MultiSelect'
import RichTextEditor from '@/components/form/RichTextEditor'
import Select from '@/components/form/select/Select'
import ArticleTypeMixin from '@/components/mixins/valueObject/ArticleTypeMixin'
import TopicCategoryMixin from '@/components/mixins/valueObject/TopicCategoryMixin'
import ButtonDeleteWithUsages from '@/components/shared/ButtonDeleteWithUsages'
import Tooltip from '@/components/tooltip/Tooltip'
import TopicModel from '@/model/TopicModel'
import NotifyService from '@/services/NotifyService'
import TinyMceConfig from '@/services/tinymce/topic/TinyMceConfig'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import { mapGetters } from 'vuex'

const slugValidation = helpers.regex('nameValidation', /^[a-z\-0-9]+$/)

export default {
  name: 'TopicEditView',
  mixins: [TopicCategoryMixin, ArticleTypeMixin],
  data () {
    return {
      mode: 'create',
      tagsRequired: [],
      tagsOptional: [],
      dataLoaded: false,
      isLoading: false,
      topic: this._.cloneDeep(TopicModel),
      richTextEditorConfig: TinyMceConfig.getConfig(),
      oldSlug: null,
      oldSite: null,
      isSlugValid: false,
      usages: {},
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    display () {
      if (this.mode === 'create' || (this.mode === 'update' && this.dataLoaded)) {
        return true
      }
      return false
    },
    sites () {
      return this.$store.getters['site/all']
    },
    rubrics () {
      return this.$store.getters['rubric/rubricsWithSite']
    },
    topicImage () {
      return this.$store.getters['topic/image']
    },
    topicImageBg () {
      return this.$store.getters['topic/imageBg']
    },
    topicUrl () {
      const site = this.$store.getters['site/siteById'](this.topic.site)
      if (site) {
        return site.domain + '/t/' + this.topic.slug
      }

      return ''
    }
  },
  validations: {
    topic: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      slug: {
        required,
        slugValidation,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      site: {
        required,
        minLength: minLength(1)
      },
      meta: {
        title: {
          maxLength: maxLength(255)
        },
        description: {
          maxLength: maxLength(255)
        },
        keywords: {
          maxLength: maxLength(255)
        }
      },
      sportTable: {
        seasonId: {
          integer,
          minValue: minValue(0)
        },
        activeTab: {
          integer,
          minValue: minValue(1)
        }
      }
    }
  },
  components: {
    ModuleFormButton,
    ModuleFormPhotoBox,
    ModuleForm,
    Input,
    Select,
    MultiSelect,
    RichTextEditor,
    Tooltip,
    ButtonDeleteWithUsages
  },
  methods: {
    getArticleTypes (values) {
      return values.map(function (value) {
        return this.articleTypeValues.find(item => item.id === value)
      }.bind(this))
    },
    async validateSlug () {
      this.isSlugValid = false
      const data = {
        slug: this.topic.slug,
        site: this.topic.site
      }
      await this.$store.dispatch('topic/fetchBySlugAndSite', data)
        .then(() => {
          if (this.oldSlug === null && this.oldSite === null) {
            // create
            if (this.$store.getters['topic/totalCount'] === 0) {
              this.isSlugValid = true
            }
          } else {
            // update
            if (this.$store.getters['topic/totalCount'] === 0) {
              this.isSlugValid = true
            } else if (this.$store.getters['topic/totalCount'] !== 0 &&
              this.oldSlug === this.topic.slug && this.oldSite === this.topic.site) {
              this.isSlugValid = true
            }
          }
        })
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('topic/storeImage', media)
      })
    },
    removeImage () {
      this.$store.commit('topic/storeImage', null)
    },
    setImageBg (medias) {
      medias.forEach(media => {
        this.$store.commit('topic/storeImageBg', media)
      })
    },
    removeImageBg () {
      this.$store.commit('topic/storeImageBg', null)
    },
    getTopic () {
      this.$store.dispatch('topic/fetchOne', this.$route.params.id)
        .then(() => {
          this.topic = this.$store.getters['topic/detail']
        })
    },
    findTagsRequired (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tagsRequired = this.$store.getters['tag/list']
          this.isLoading = false
        })
    },
    findTagsOptional (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tagsOptional = this.$store.getters['tag/list']
          this.isLoading = false
        })
    },
    prepareRequest (topic) {
      const topicRequest = this._.cloneDeep(topic)
      topicRequest.tagsRequired = topic.expanded.tagsRequired.map(tag => tag.id)
      topicRequest.tagsOptional = topic.expanded.tagsOptional.map(tag => tag.id)
      topicRequest.rubricsSelected = topic.expanded.rubricsSelected.map(rubric => rubric.id)
      topicRequest.sitesSelected = topic.expanded.sitesSelected.map(site => site.id)
      topicRequest.articleTypes = topic.expanded.articleTypes.map(type => type.id)
      if (topicRequest.expanded) {
        delete topicRequest.expanded
      }
      topicRequest.image = null
      if (this.$store.getters['topic/image']) {
        topicRequest.image = this.$store.getters['topic/image'].id
      }
      topicRequest.imageBg = null
      if (this.$store.getters['topic/imageBg']) {
        topicRequest.imageBg = this.$store.getters['topic/imageBg'].id
      }
      return topicRequest
    },
    async save () {
      this.$v.$touch()
      this.validateSlug()
        .then(() => {
          if (this.$v.$invalid || this.isSlugValid === false) {
            if (this.$v.$invalid) {
              NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
            }
            if (this.isSlugValid === false) {
              NotifyService.setErrorMessage(this.$t('topic.notify.slug_site_combination_must_be_unique'))
            }
            return
          }
          this.$store.dispatch('topic/create', this.prepareRequest(this.topic))
            .then(() => {
              if (this.$store.getters['topic/error'] === null) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
                this.topic = this.$store.getters['topic/detail']
                this.goToEdit()
              } else {
                NotifyService.setErrorMessage(this.$store.getters['topic/error'])
              }
            })
            .catch(error => console.log(error))
        })
    },
    goToEdit () {
      this.$router.push('/topic/' + this.topic.id + '/edit')
    },
    checkUsages () {
      this.$store.commit('topic/setUsagesId', this.topic.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('topic/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['topic/usagesList']
          this.usages.totalCount = this.$store.getters['topic/usagesTotalCount']
          this.usages.page = this.$store.getters['topic/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('topic/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteRecord () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('topic/deleteRecord', this.topic)
        .then(() => {
          if (this.$store.getters['topic/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/topic')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['topic/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.mode = 'create'
    this.$store.commit('topic/storeImage', null)
    this.$store.commit('topic/storeImageBg', null)
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.two-columns {
  display: flex;
  gap: 2%;
  &__column {
    flex: 0 0 49%;
  }
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.header-text {
  @include font(400 20px "Roboto");
  color: #465674;
  margin-bottom: rem(8px);
}
</style>
